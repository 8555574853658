import { Link } from '../ui/Link';

type projectInfo = {
  title: string;
  link?: string;
};

interface Project {
  project: projectInfo[];
  notes?: React.ReactNode;
}

const myGithub: Project = {
  project: [
    {
      title: 'My GitHub',
    },
    {
      title: 'https://github.com/rafgraph',
      link: 'https://github.com/rafgraph',
    },
  ],
  notes: 'typescript, javascript, react, open source',
};

const spaGithubPages: Project = {
  project: [
    {
      title: 'Single Page Apps for GitHub Pages',
    },
    {
      title: 'github',
      link: 'https://github.com/rafgraph/spa-github-pages',
    },
  ],
  notes: 'utility to host spas with gh-pages',
};

const fractal: Project = {
  project: [
    {
      title: 'Fractal Generator',
    },
    {
      title: 'app',
      link: 'https://fractal.rafgraph.dev',
    },
    {
      title: 'github',
      link: 'https://github.com/rafgraph/fractal',
    },
  ],
  notes: 'web app, zero libraries, responsive, mobile',
};

const reactRouterHashLink: Project = {
  project: [
    {
      title: 'React Router Hash Link',
    },
    {
      title: 'demo',
      link: 'https://react-router-hash-link.rafgraph.dev',
    },
    {
      title: 'github',
      link: 'https://github.com/rafgraph/react-router-hash-link',
    },
    {
      title: 'npm',
      link: 'https://www.npmjs.com/package/react-router-hash-link',
    },
  ],
  notes: 'hash link scroll functionality for react router',
};

const fourCornerLayout: Project = {
  project: [
    {
      title: 'Four Corner Layout',
    },
    {
      title: 'demo',
      link: 'https://four-corner-layout.rafgraph.dev',
    },
    {
      title: 'github',
      link: 'https://github.com/rafgraph/four-corner-layout',
    },
  ],
  notes: 'design concept, react, responsive',
};

const mySite: Project = {
  project: [
    {
      title: 'My Website',
    },
    {
      title: 'https://rafaelpedicini.com',
      link: 'https://rafaelpedicini.com',
    },
  ],
  notes: 'design, react, responsive',
};

const fluxDispatcher: Project = {
  project: [
    {
      title: 'Async Flux Dispatcher',
    },
    {
      title: 'github',
      link: 'https://github.com/rafgraph/flux-async-dispatcher',
    },
  ],
  notes: 'react, asynchronous dispatcher for the flux pattern',
};

const reactGithubPages: Project = {
  project: [
    {
      title: 'React GitHub Pages',
    },
    {
      title: 'github',
      link: 'https://github.com/rafgraph/react-github-pages',
    },
  ],
  notes: (
    <>
      react, predecessor to{' '}
      <Link
        href="https://github.com/rafgraph/spa-github-pages"
        css={{ color: '$lowContrast' }}
      >
        spa-github-pages
      </Link>
    </>
  ),
};

const detectIt: Project = {
  project: [
    {
      title: 'Detect It',
    },
    {
      title: 'live detection',
      link: 'https://detect-it.rafgraph.dev',
    },
    {
      title: 'github',
      link: 'https://github.com/rafgraph/detect-it',
    },
    {
      title: 'npm',
      link: 'https://www.npmjs.com/package/detect-it',
    },
  ],
  notes: 'detect device type (mouse, touch, hybrid)',
};

const detectPassiveEvents: Project = {
  project: [
    {
      title: 'Detect Passive Events',
    },
    {
      title: 'github',
      link: 'https://github.com/rafgraph/detect-passive-events',
    },
    {
      title: 'npm',
      link: 'https://www.npmjs.com/package/detect-passive-events',
    },
  ],
  notes: 'detect if the browser supports passive event listeners',
};

const eventFrom: Project = {
  project: [
    {
      title: 'Event From',
    },
    {
      title: 'demo',
      link: 'https://event-from.rafgraph.dev',
    },
    {
      title: 'github',
      link: 'https://github.com/rafgraph/event-from',
    },
    {
      title: 'npm',
      link: 'https://www.npmjs.com/package/event-from',
    },
  ],
  notes: 'determine if an event is from mouse, touch or key input',
};

const theListener: Project = {
  project: [
    {
      title: 'The Listener',
    },
    {
      title: 'github',
      link: 'https://github.com/rafgraph/the-listener',
    },
    {
      title: 'npm',
      link: 'https://www.npmjs.com/package/the-listener',
    },
  ],
  notes: 'easily set complex mouse and touch listeners',
};

const currentInput: Project = {
  project: [
    {
      title: 'Current Input',
    },
    {
      title: 'demo',
      link: 'https://current-input.rafgraph.dev',
    },
    {
      title: 'github',
      link: 'https://github.com/rafgraph/current-input',
    },
    {
      title: 'npm',
      link: 'https://www.npmjs.com/package/current-input',
    },
  ],
  notes: 'fixes the sticky :hover bug on touch devices',
};

const reactInteractive: Project = {
  project: [
    {
      title: 'React Interactive',
    },
    {
      title: 'demo',
      link: 'https://react-interactive.rafgraph.dev',
    },
    {
      title: 'github',
      link: 'https://github.com/rafgraph/react-interactive',
    },
    {
      title: 'npm',
      link: 'https://www.npmjs.com/package/react-interactive',
    },
  ],
  notes: 'better interactive states than css pseudo-classes',
};

const fscreen: Project = {
  project: [
    {
      title: 'Fscreen',
    },
    {
      title: 'demo',
      link: 'https://fscreen.rafgraph.dev',
    },
    {
      title: 'github',
      link: 'https://github.com/rafgraph/fscreen',
    },
    {
      title: 'npm',
      link: 'https://www.npmjs.com/package/fscreen',
    },
  ],
  notes: 'vendor agnostic access to the fullscreen api',
};

const reactMarkdownTree: Project = {
  project: [
    {
      title: 'React Markdown Tree',
    },
    {
      title: 'demo',
      link: 'https://react-markdown-tree.rafgraph.dev',
    },
    {
      title: 'github',
      link: 'https://github.com/rafgraph/react-markdown-tree',
    },
    {
      title: 'npm',
      link: 'https://www.npmjs.com/package/react-markdown-tree',
    },
  ],
  notes: 'react markdown renderer',
};

const reactMarkdownTreeConfigDefault: Project = {
  project: [
    {
      title: 'React Markdown Tree Style',
    },
    {
      title: 'demo',
      link: 'https://react-markdown-tree.rafgraph.dev',
    },
    {
      title: 'github',
      link: 'https://github.com/rafgraph/react-markdown-tree-config-default',
    },
    {
      title: 'npm',
      link: 'https://www.npmjs.com/package/react-markdown-tree-config-default',
    },
  ],
  notes: 'style config for react markdown tree',
};

const reactFastMount: Project = {
  project: [
    {
      title: 'React Fast Mount',
    },
    {
      title: 'demo',
      link: 'https://react-fast-mount.rafgraph.dev',
    },
    {
      title: 'github',
      link: 'https://github.com/rafgraph/react-fast-mount',
    },
    {
      title: 'npm',
      link: 'https://www.npmjs.com/package/react-fast-mount',
    },
  ],
  notes: 'fast mount slow rendering components',
};

const simplemark: Project = {
  project: [
    {
      title: 'Simplemark',
    },
    {
      title: 'demo',
      link: 'https://simplemark.rafgraph.dev',
    },
    {
      title: 'github',
      link: 'https://github.com/rafgraph/simplemark',
    },
    {
      title: 'npm',
      link: 'https://www.npmjs.com/package/simplemark',
    },
  ],
  notes: 'a smaller version of markdown',
};

const reactSimplemark: Project = {
  project: [
    {
      title: 'React Simplemark',
    },
    {
      title: 'demo',
      link: 'https://simplemark.rafgraph.dev',
    },
    {
      title: 'github',
      link: 'https://github.com/rafgraph/react-simplemark',
    },
    {
      title: 'npm',
      link: 'https://www.npmjs.com/package/react-simplemark',
    },
  ],
  notes: 'react renderer for simplemark',
};

const rafaelPhoto: Project = {
  project: [
    {
      title: 'My Photography Gallery',
    },
    {
      title: 'app',
      link: 'https://rafael.photo',
    },
  ],
  notes: 'react, apollo, graphql, node, device responsive app',
};

const browserlistsCssGrid: Project = {
  project: [
    {
      title: 'Browserslist Config for CSS Grid',
    },
    {
      title: 'github',
      link: 'https://github.com/rafgraph/browserslist-config-css-grid',
    },
    {
      title: 'npm',
      link: 'https://www.npmjs.com/package/browserslist-config-css-grid',
    },
  ],
  notes: 'css grid browserslist',
};

const rollpkg: Project = {
  project: [
    {
      title: 'Rollpkg',
    },
    {
      title: 'github',
      link: 'https://github.com/rafgraph/rollpkg',
    },
    {
      title: 'npm',
      link: 'https://www.npmjs.com/package/rollpkg',
    },
  ],
  notes: 'zero config build tool to create packages with rollup and typescript',
};

const rollpkgExamplePackage: Project = {
  project: [
    {
      title: 'Rollpkg Example Package',
    },
    {
      title: 'demo',
      link: 'https://rollpkg.rafgraph.dev',
    },
    {
      title: 'github',
      link: 'https://github.com/rafgraph/rollpkg-example-package',
    },
    {
      title: 'npm',
      link: 'https://www.npmjs.com/package/rollpkg-example-package',
    },
  ],
  notes: 'example package for rollpkg',
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const example: Project = {
  project: [
    {
      title: '',
    },
    {
      title: '',
      link: '',
    },
  ],
  notes: '',
};

export const codeProjects: Project[] = [
  myGithub,
  reactInteractive,
  reactRouterHashLink,
  spaGithubPages,
  rollpkg,
  rollpkgExamplePackage,
  detectIt,
  detectPassiveEvents,
  eventFrom,
  fractal,
  rafaelPhoto,
  fscreen,
  simplemark,
  reactSimplemark,
  reactMarkdownTree,
  reactMarkdownTreeConfigDefault,
  browserlistsCssGrid,
  reactFastMount,
  theListener,
  currentInput,
  fourCornerLayout,
  fluxDispatcher,
  reactGithubPages,
  mySite,
];
