import { createInteractive } from 'react-interactive';
import { Link } from 'react-router-dom';
import { ArrowLeftIcon } from '@radix-ui/react-icons';
import { Button } from './Button';

// any because of Stitches TS issue when use as={InteractiveLink} inside <Button>
const InteractiveLink: any = createInteractive(Link);

interface HomeArrowIconLinkProps {
  css?: React.ComponentProps<typeof Button>['css'];
}

export const HomeArrowIconLink: React.VFC<HomeArrowIconLinkProps> = ({
  css,
}) => (
  <Button
    as={InteractiveLink}
    to="/"
    title="Home"
    aria-label="Home"
    focus="boxShadow"
    css={{
      display: 'inline-block',
      width: '36px',
      height: '36px',
      padding: '3px',
      margin: '-3px',
      borderRadius: '50%',
      // cast as any b/c of Stitches bug: https://github.com/modulz/stitches/issues/407
      ...(css as any),
    }}
  >
    <ArrowLeftIcon width="30" height="30" style={{ transform: 'scale(1.2)' }} />
  </Button>
);
