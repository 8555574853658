import { Switch, Route, Redirect } from 'react-router-dom';
import { Home } from './components/Home';
import { Code } from './components/Code';
import { Contact } from './components/Contact';
import { DarkModeButton } from './ui/DarkModeButton';
import { globalCss } from './stitches.config';

export const App: React.VFC = () => {
  globalCss();

  return (
    <>
      <DarkModeButton
        css={{ position: 'absolute', top: '12px', right: '16px' }}
      />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/code" component={Code} />
        <Route exact path="/contact" component={Contact} />
        <Route render={() => <Redirect to="/" />} />
      </Switch>
    </>
  );
};
