import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { App } from './App';

Sentry.init({
  dsn:
    'https://0af15dbce081449aafb591c616685c45@o456943.ingest.sentry.io/5752631',
});

ReactDOM.render(
  <StrictMode>
    <Sentry.ErrorBoundary fallback={<>An error has occurred 🙁</>}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Sentry.ErrorBoundary>
  </StrictMode>,
  document.getElementById('root'),
);
