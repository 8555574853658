import * as React from 'react';
import { Link } from '../ui/Link';
import { HomeArrowIconLink } from '../ui/HomeArrowIconLink';
import { styled } from '../stitches.config';
import { codeProjects } from '../content/codeProjects';

const CodePageContainer = styled('div', {
  minHeight: '100%',
  padding: '40px 20px 70px',
  backgroundColor: 'inherit',
  display: 'grid',
  gridTemplateColumns: 'auto',
  gridTemplateRows: 'auto auto',
  gridRowGap: '40px',
  justifyContent: 'center',
});

const ContentContainer = styled('div', {
  maxWidth: '800px',
});

const CodeHeading = styled('h1', {
  fontSize: '34px',
});

const Li = styled('li', {
  margin: '15px 0 15px 22px ',
  listStyleType: '"–  "',
  fontSize: '17px',
  '&::marker': {
    color: '$lowContrast',
  },
});

const Separator = styled('span', {
  color: '$lowContrast',
});

const ProjectNotes = styled('span', {
  color: '$lowContrast',
});

export const Code: React.VFC = () => {
  return (
    <CodePageContainer>
      <ContentContainer>
        <CodeHeading>Code</CodeHeading>
        <ul>
          {codeProjects.map((code) => (
            <Li key={code.project[0].title}>
              {code.project.map((item, idx, array) => (
                <React.Fragment key={`${idx}${item.title}`}>
                  {item.link ? (
                    <Link href={item.link}>{item.title}</Link>
                  ) : (
                    item.title
                  )}
                  {idx === 0 ? (
                    <Separator> &nbsp;–&nbsp; </Separator>
                  ) : idx + 1 < array.length ? (
                    <Separator> - </Separator>
                  ) : null}
                </React.Fragment>
              ))}
              <Separator> &nbsp;...&nbsp; </Separator>
              <ProjectNotes>{code.notes}</ProjectNotes>
            </Li>
          ))}
        </ul>
      </ContentContainer>
      <HomeArrowIconLink css={{ justifySelf: 'center' }} />
    </CodePageContainer>
  );
};
