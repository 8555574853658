import { HomeArrowIconLink } from '../ui/HomeArrowIconLink';
import { Link } from '../ui/Link';
import { styled } from '../stitches.config';
import rafael280 from '../images/rafael_pedicini-280.jpg';
import rafael560 from '../images/rafael_pedicini-560.jpg';

const ContactPageContainer = styled('div', {
  minHeight: '100%',
  padding: '40px 0 20px',
  backgroundColor: 'inherit',
  display: 'grid',
  gridRowGap: '15px',
  gridTemplateColumns: 'auto',
  gridTemplateRows: '1.1fr auto 1.1fr auto 1.6fr',
  gridTemplateAreas: `
    '.'
    'content'
    '.'
    'backArrow'
    '.'
  `,
  justifyContent: 'center',
});

const ContentContainer = styled('div', {
  gridArea: 'content',
});

const RafaelImage = styled('img', {
  display: 'block',
  width: '280px',
  height: '280px',
  borderRadius: '7px',
});

const TextContainer = styled('div', {
  margin: '7px 0 0 2px',
  fontSize: '18px',
});

const Name = styled('h1', {
  fontSize: '26px',
});

const Li = styled('li', {
  margin: '5px 0 5px 21px ',
  listStyleType: '"–  "',
  '&::marker': {
    color: '$lowContrast',
  },
});

export const Contact: React.VFC = () => {
  return (
    <ContactPageContainer>
      <ContentContainer>
        <RafaelImage
          src={rafael280}
          srcSet={`${rafael560} 2x`}
          alt="Rafael Pedicini"
        />
        <TextContainer>
          <Name>Rafael Pedicini</Name>
          <ul>
            <Li>New York, NY</Li>
            <Li>
              <Link href="mailto:rafael@rafgraph.dev">rafael@rafgraph.dev</Link>
            </Li>
            <Li>
              <Link href="https://github.com/rafgraph">GitHub</Link> -{' '}
              <Link href="https://www.linkedin.com/in/rafgraph">LinkedIn</Link>{' '}
              -{' '}
              <Link href="https://www.instagram.com/rafgraph/">Instagram</Link>
            </Li>
          </ul>
        </TextContainer>
      </ContentContainer>
      <HomeArrowIconLink
        css={{ gridArea: 'backArrow', justifySelf: 'center' }}
      />
    </ContactPageContainer>
  );
};
