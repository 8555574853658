import { Link } from 'react-router-dom';
import { Interactive, createInteractive } from 'react-interactive';
import { styled, CSS } from '../stitches.config';

const Nav = styled('nav', {
  height: '100%',
  fontSize: '16vmin',
  fontWeight: '300',
  letterSpacing: '1px',
});

const navItemSharedStyle: CSS = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '&.hover, &.active': {
    color: '$green',
  },
  '&.focusFromKey': {
    outlineOffset: '-5px',
    outline: '5px solid $colors$purple',
    textDecorationLine: 'underline',
    textDecorationColor: '$purple',
    textDecorationThickness: 'from-font',
    textDecorationStyle: 'dotted',
  },
};

const Code = styled(createInteractive(Link), {
  ...navItemSharedStyle,
  height: '33%',
  backgroundColor: '$navBackgroundLight',
});

const Photography = styled(Interactive.A, {
  ...navItemSharedStyle,
  height: '33%',
  backgroundColor: '$navBackgroundMedium',
});

const Contact = styled(createInteractive(Link), {
  ...navItemSharedStyle,
  height: '34%',
  backgroundColor: '$navBackgroundDark',
});

export const Home: React.VFC = () => {
  return (
    <>
      <Nav>
        <Code to="/code">Code</Code>
        <Photography href="https://rafael.photo">Photography</Photography>
        <Contact to="/contact">Contact</Contact>
      </Nav>
    </>
  );
};
